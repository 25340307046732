@import 'styles';

.tableWrapper {
	height: 83%;
	width: 100%;
}

.oddRow {
	background-color: var(--palette-background-default);
}

.tableActions {
	@include d-flex(null, null, center);
	margin-bottom: 8px;

	&__items {
		display: flex;
		gap: 12px;
	}

	&__apartments {
		@include d-flex(null, null, center);
		gap: 12px;

		> div {
			width: 250px;
		}
	}
}

.select {
	@include d-flex(null, null, center);
	gap: 12px;
	padding-bottom: 10px;

	> div {
		width: 250px;
	}

	&__label {
		margin-bottom: 4px;
	}
}
