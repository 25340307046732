@import 'styles';

.wrapper {
    @include d-flex(column);
    gap: 8px;
    width: 100%;
    padding-left: 16px;
    height: 100%;
    background: none;
}
