.modal {
    
    :global(.MuiDialog-paper){ 
        max-width: 80vw;
        min-width: 448px;
        width: max-content;
        padding: 24px;
    }
}

.cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
