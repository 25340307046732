@import 'styles';

.content {
	width: 100%;
	padding: 16px;
	border-radius: 8px;
	background-color: var(--palette-background-default);
	box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);

	.date {
		@include d-flex();
		gap: 12px;
		margin: 12px 0;
	}

	&__reportsList {
		max-height: 80vh;
		overflow-y: auto;
	}

	&__reportItem {
		@include d-flex(null, space-between, center);
	}
}
