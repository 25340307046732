@import 'styles';
.wrapper {
	max-width: 684px;
	display: grid;
	gap: 12px;

	.actions {
		display: flex;
		gap: 12px;
		width: 100%;
		& > button {
			width: 100%;

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}
}
