@import 'styles';

.page {
	&__details {
		width: 100%;
		background-color: var(--palette-background-default);
		box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);
		border-radius: 8px;

		&_header {
			font-weight: 700;
			color: var(--palette-common-textLightGray);
			padding: 16px;
		}

		&_content {
			font-weight: 400;
		}

		&_item {
			@include d-flex(null, space-between);
			padding: 18px;
			font-weight: 400;

			h5 {
				color: var(--palette-common-textLightGray);
				font-weight: 400;
			}
		}

		&_editor {
			display: flex;
			gap: 5px;
			align-items: center;

			img {
				margin-bottom: 2px;
			}
		}

		&_divider {
			padding: 0 16px;
		}
	}
}
