@import 'styles';

.wrapper {
	@include d-flex(null, space-between, center);

	.file_name {
		@include d-flex(null, center, center);
		gap: 8px;
	}
}
