@import 'styles';

.filter {
	@include d-flex(row, null, center);
	gap: 12px;

	.date {
		&__filters {
			@include d-flex(row, space-between, center);
			gap: 4px;
		}

		&__input {
            max-width: 170px;

			input {
				padding-right: 0;
			}
		}
	}

    .select {
        width: 220px;
    }
}
