@import 'styles';

.layout {
	@include d-flex(null, center, center);
	width: 100vw;
	height: 100vh;

	.card {
		max-width: 448px;
		width: 100%;
	}

	.content {
		@include square(100%);
		align-items: center;

		&__title {
			margin: 40px 0 24px 0;
		}
	}
}
