@import 'styles';

.title > .close {
	position: absolute;
	right: 16px;
	top: 16px;
}

.content {
	width: 100%;
}
