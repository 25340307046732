@import 'styles';

.wrapper {
	@include d-flex(row, space-between, center);
	height: 82px;
	padding: 20px 24px;

	.container {
		@include d-flex(row, flex-start, center);
		width: 100%;
		gap: 20px;

		.back-button {
			font-size: 20px;
			font-weight: 700;
			border: none;
			color: var(--palette-text-primary)
		}
	}
}
