@use 'styles' as *;

.form {
    width: 100%;

    @include d-flex(column);
    gap: 16px;

    .button {
        margin-top: 16px;
    }
}
