@import 'styles';

.content {
	@include d-flex(column, center, center);
	gap: 16px;
	max-width: 800px;
	min-width: 448px;
	min-height: 264px;
	padding: 24px 60px;
	text-align: center;
	border-radius: 8px;
	background-color: var(--palette-background-default);
}
