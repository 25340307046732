@import 'styles';

.tableWrapper {
	height: calc(100% - 58px);
	width: 100%;
}

.tableActions {
	@include d-flex(null, null, center);
	margin-bottom: 8px;

	&__items {
		display: flex;
		gap: 12px;
	}
	&__status {
		height: 36px;
		max-width: 160px;
	}
}

.date {
	&__filters {
		@include d-flex(row, center, center);
		gap: 4px;
	}

	&__input {
		max-width: 160px;
	}
}

.oddRow {
	background-color: var(--palette-background-default);
}
