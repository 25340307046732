@import 'styles';

.wrapper {
	display: flex;
	gap: 20px;

	.content {
		max-width: 684px;
		display: grid;
		gap: 12px;
		flex: 0 0 50%;
		height: fit-content;

		.actions {
			display: flex;
			gap: 12px;

			& > button {
				width: 100%;

				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 10px;
		overflow: auto;
		max-height: 570px;
		padding-right: 5px;
	}
}
