@import 'styles';

.tableWrapper {
	height: 85%;
	width: 100%;
}

.oddRow {
	background-color: var(--palette-background-default);
}

.filter {
	@include d-flex(null, null, center);
	gap: 12px;
	padding-bottom: 10px;

	> div {
		width: 250px;
	}

	&__checkbox {
		display: flex;
		align-items: center;
	}

	&__text {
		width: 300px !important;

		input[type='number'] {
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}
}

.date {
	&__filters {
		@include d-flex(row, space-between, center);
		width: 350px !important;
		gap: 4px;
	}
}
