@import 'styles';

.tableActions {
	@include d-flex(null, null, center);
	margin-bottom: 8px;

	&__items {
		display: flex;
		gap: 12px;
	}

	&__apartments {
		@include d-flex(null, null, center);

		gap: 12px;

		> div {
			width: 200px;
		}
	}
}

.tableWrapper {
	height: calc(100% - 52px);
	width: 100%;
}

.oddRow {
	background-color: var(--palette-background-default);
}
