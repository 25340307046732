.wrapper {
	max-width: 684px;
	display: grid;
	gap: 12px;
	height: 97%;
	overflow-y: scroll;
	padding-right: 10px;

	.actions {
		display: flex;
		gap: 12px;

		button {
			width: 100%;
		}
	}

	.fee {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
}
