@import 'styles';
.wrapper {
	@include d-flex();
	gap: 24px;
	min-width: 806px;

	&__container {
		@include d-flex(column);
		gap: 12px;
		width: 100%;
	}

	.content {
		width: 100%;
		padding: 16px;
		border-radius: 8px;
		background-color: var(--palette-background-default);
		box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);

		.date {
			@include d-flex();
			gap: 12px;
			margin: 12px 0;
		}

		&__providers {
			@include d-flex(null, null, center);
			padding: 12px 0;
		}
	}

	.checkbox {
		appearance: none;
		width: 20px;
		height: 20px;
		padding: 1px;
		border: 2px solid var(--palette-common-textLightGray);
		border-radius: 4px;
		margin-right: 8px;
		position: relative;
		cursor: pointer;
		transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		box-shadow: inset 0 0 0 2px var(--palette-background-default);

		&:checked {
			background-color: var(--palette-common-green);

			border-color: var(--palette-common-green);
		}

		&:focus {
			outline: none;
		}
	}

	.action {
		width: 100%;
	}
}
