@import 'styles';

.profile {
	@include d-flex(null, center, center);
	gap: 12px;
	margin: 16px;
	text-decoration: none;
	color: var(--palette-text-primary);
	&:is(:active, :visited) {
		border: 0;
		text-decoration: none;
	}

	&__avatar {
		@include square(40px, true);
	}
}
