@import 'styles';

.wrapper {
	width: 100%;
	position: relative;

	.action {
		width: 100%;
		font-size: 16px;
		//background-color: var(--color-primary-main);
		//color: var(--palette-background-default);
		border-radius: 40px;
		padding: 12px 20px;
	}
	.form {
		@include d-flex(column);
		margin-bottom: 40px;
		gap: 24px;
	}
}

.forgotPassword {
	@include d-flex(null, center, center);
	gap: 8px;
	margin-top: 30px;
}
