@import 'styles';

.wrapper {
	display: flex;
	gap: 20px;

	.content {
		max-width: 684px;
		display: grid;
		gap: 12px;
		flex: 0 0 50%;
		height: fit-content;

		.actions {
			display: flex;
			gap: 12px;

			& > button {
				width: 100%;

				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.activity {
		margin-bottom: 12px;
		margin-left: auto;
		display: flex;
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 10px;
		overflow: auto;
		max-height: 620px;
		padding-right: 5px;

		.rbIcon {
			width: 20px;
		}

		&__card {
			display: flex;
			gap: 4px;
			align-items: center;
			letter-spacing: 1px;
		}
	}
}
