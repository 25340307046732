@import 'styles';

.wrapper {
	max-width: 684px;
	display: grid;
	gap: 12px;

	.content {
		background-color: var(--palette-background-default);
		box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);
		padding: 16px;
		border-radius: 8px;
		width: 100%;

		.loadingWrapper {
			@include d-flex(column, center, center);
			height: 240px;

			&__process {
				width: 100%;
				margin-bottom: 40px;
			}
		}
	}
	.actions {
		@include d-flex(column, center, center);
		text-align: center;
		width: 100%;
		gap: 8px;

		&_button {
			width: 100%;
			align-items: center;
			cursor: pointer;
		}
	}
	.actionWrapper {
		@include d-flex(null, space-between, center);
		margin: 10px 0;

		.actionWrapperBut {
			display: flex;
			gap: 15px;
		}
	}
}
//TODO: CHANGE STYLE IN FUTURE
.circle {
	padding: 5px;
	border: 1px solid darkcyan;
	display: flex;
	gap: 15px;
	align-items: center;

	div {
		width: 100%;
		font-size: 18px;
		font-weight: 700;
	}
}
