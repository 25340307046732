@import 'styles';

.wrapper {
	position: relative;
	width: 100%;
	height: 1px;
	margin-top: 8px;

	.observer {
		height: 1px;
		display: hidden;
	}
}
