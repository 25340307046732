@import 'styles';

.progress {
	@include d-flex(null, center);
	width: 100%;
	position: relative;
	padding: 8px 0;

	&__info {
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
