@import 'styles';

.wrapper {
	width: 100%;

	& > div:first-of-type {
		@include d-flex(column);
		gap: 24px;
	}
}

.dialog {
	&__close {
		position: absolute !important;
		right: 16px;
		top: 16px;
	}
}
