@mixin d-flex($direction: null, $justifyContent: null, $alignItems: null) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justifyContent;
	align-items: $alignItems;
}

@mixin square($size, $round: false) {
	width: $size !important;
	height: $size !important;

	@if $round {
		border-radius: 50%;
	}
}

@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
