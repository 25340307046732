@import 'styles';

.wrapper {
	&__info {
		@include d-flex(null, null, center);
		gap: 10px;
		width: 100%;
		background-color: var(--palette-background-default);
		padding: 16px;
		border-radius: 8px;
		font-weight: 700;
		box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);
	}
	.avatar {
		@include square(60px, true);
	}
}
