@import 'styles';
.wrapper {
	width: 100%;
	.action {
		width: 100%;
		font-size: 16px;
		background-color: var(--palette-primary-main);
		color: var(--palette-background-default);
		border-radius: 40px;
		padding: 12px 20px;
	}
	.form {
		@include d-flex(column, center, center);
		gap: 24px;
		margin-bottom: 40px;
	}
	.info {
		text-align: center;
	}
}
