.wrapper {
	border-right: 1px solid var(--palette-primary-light);
	display: flex;
	flex-direction: column;
	height: 100vh;

	.logo {
		padding: 32px 30px 40px 20px;
	}

	.navbar {
		flex-grow: 1;
	}

	.version {
		display: block;
		font-size: 14px;
		position: sticky;
		bottom: 0;
		margin-top: auto;
		padding: 12px 24px;
		background-color: #fff;
		width: 100%;
		color: #4d7c79;
		opacity: 0.8;
	}
}
