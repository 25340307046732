@import 'styles';

.wrapper {
	max-width: 684px;
	margin-top: 12px;
	display: grid;
	gap: 12px;

	.address {
		&__info {
			@include d-flex(null, null, center);
			width: 100%;
			height: 56px;
			background-color: var(--palette-background-default);
			padding: 16px;
			border-radius: 8px;
			font-weight: 700;
			box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);
		}

		&__details {
			width: 100%;
			background-color: var(--palette-background-default);
			box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);
			border-radius: 8px;

			&_header {
				font-weight: 700;
				color: #757877;
				padding: 16px;
			}

			&_content {
				font-weight: 400;
			}

			&_item {
				@include d-flex(null, space-between);
				padding: 18px;
				font-weight: 400;

				h5 {
					color: #757877;
					font-weight: 400;
				}
			}

			&_divider {
				padding: 0 16px;
			}
		}
	}

	.actions {
		&_update {
			width: 100%;
		}
	}

	.modal {
		&__content {
			width: 100%;

			&_input {
				width: 100%;
				font-size: 700;
			}

			&_action {
				margin-top: 40px;
				border-radius: 40px;
				padding: 15px 22px;
				font-size: 16px;
				font-weight: 700;
				width: 100%;
			}
		}
	}
}
