@import 'styles';

.wrapper {
	@include d-flex(row);

	.main {
		height: 100vh;
		width: 100%;
		max-width: 88%;
	}

	.pages {
		background-color: var(--palette-background-paper);
		padding: 8px 24px 0 8px;
		height: calc(100vh - 82px);
	}
}
