@import 'styles';

.tableWrapper {
	height: 93%;
	width: 100%;
}

.oddRow {
	background-color: var(--palette-background-default);
}

.custom {
	background: #f8f3eb;
}

.filter {
	@include d-flex(null, null, center);
	gap: 12px;
	padding-bottom: 10px;

	> div {
		width: 300px;
	}

	&__checkbox {
		display: flex;
		align-items: center;
	}
}
