@import 'styles/abstracts/mixins';

.cell {
	@include d-flex(column, center);
	height: 100%;
    width: 100%;
    overflow: hidden;

	& > span {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
        width: 100%;
	}
}
