@import 'styles';

.wrapper {
	display: flex;
	gap: 12px;

	.container {
		flex-grow: 1;
		max-width: 684px;
		display: grid;
		gap: 12px;
		height: fit-content;

		.actions {
			display: flex;
			gap: 12px;

			& > button {
				width: 100%;

				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.button {
			max-width: 280px;
			width: 100%;
		}
	}
}
